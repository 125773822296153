import { IMAGE_ASSET_BASE_URL } from '@framework/utils/constants'
import React from 'react'

const FooterLogo = ({ className = '', ...props }) => {
  return (
    <img
      //
      // placeholder='blur'
      className="w-auto"
      src={`${IMAGE_ASSET_BASE_URL}/icons/logo-footer.png`}
      alt="DaMENSCH"
      style={{
        height: '65px',
        width: '180px',
      }}
    />
  )
}

export default FooterLogo
